import { render, staticRenderFns } from "./StudentGroupSearch.vue?vue&type=template&id=626ab82a&scoped=true&"
import script from "./StudentGroupSearch.vue?vue&type=script&lang=js&"
export * from "./StudentGroupSearch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "626ab82a",
  null
  
)

export default component.exports